import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  successResponse: {},
  isLoading: false,
  isError: false,
  error: false
};
const baggageClaimSlice = createSlice({
  name: "baggageClaim",
  initialState,
  reducers: {
    baggageClaimRequest: (state, action) => {
      state.isLoading = true;
      state.error = false;
      state.isError = false;
    },
    baggageClaimSuccess: (state, action) => {
      state.isLoading = false;
      state.error = "";
      state.hasData = true;
      state.successResponse = action.payload;
    },
    baggageClaimFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isError = true;
    }
  }
});
export const {
  baggageClaimRequest,
  baggageClaimSuccess,
  baggageClaimFailure
} = baggageClaimSlice.actions;
export default baggageClaimSlice.reducer;